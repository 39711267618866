:root {
	--main: 255, 225, 0;
	--mainDark: 225, 200, 0;
	--transparent: rgba(0, 0, 0, 0.65);
}

@font-face {
	font-family: "LifeCraft";
	src: url("/assets/fonts/LifeCraft.ttf");
}

@font-face {
	font-family: "Montserrat-Black";
	src: url("/assets/fonts/Montserrat-Black.ttf");
}

*,
*::before,
*::after {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	list-style: none;
}

::-webkit-scrollbar {
	width: 1rem;
}

::-webkit-scrollbar-thumb {
	background-color: rgb(var(--main));
	background-clip: padding-box;
	border: 4px solid transparent;
	border-radius: 100px;
}

::selection {
	background-color: rgb(var(--main));
	color: black;
}

body,
#root {
	font-family: "Montserrat-Black";
	text-align: center;
	min-height: 100vh;
	color: rgb(var(--main));
	text-transform: uppercase;
}

#root {
	display: flex;
	flex-direction: column;
}

body {
	overflow: overlay;
	background-color: black;
	background-image: url("/assets/images/background.png");
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	&::after {
		background-color: var(--transparent);
		content: "";
		position: fixed;
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
		z-index: -1;
	}
}

a,
button {
	cursor: pointer;
}
